'use client';

import { ApolloLink, HttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import {
  ApolloNextAppProvider,
  NextSSRInMemoryCache,
  NextSSRApolloClient,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';

const API_URL = process.env.NEXT_PUBLIC_API_URL || '';
const SOCKET_URL = process.env.NEXT_PUBLIC_SOCKET_URL || '';

const APP_API_URL = process.env.NEXT_PUBLIC_APP_API_URL || '';
const APP_API_URL_JWT = process.env.NEXT_PUBLIC_APP_API_URL_JWT || '';

function makeClient() {
  const httpLink = new HttpLink({
    uri: API_URL,
  });

  const httpLinkApp = new HttpLink({
    uri: APP_API_URL,
  });

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('accessToken');
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const authLinkApp = setContext((_, { headers }) => {
    const token = localStorage.getItem('token') || APP_API_URL_JWT;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const combineLinkApp = () =>
    ApolloLink.split(
      (operation) => operation.getContext().clientName === 'httpLinkApp',
      authLinkApp.concat(httpLinkApp),
      authLink.concat(httpLink),
    );

  const wsLink = new GraphQLWsLink(
    createClient({
      url: SOCKET_URL,
    }),
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    combineLinkApp(),
  );

  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache(),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            splitLink,
          ])
        : splitLink,
  });
}

export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return <ApolloNextAppProvider makeClient={makeClient}>{children}</ApolloNextAppProvider>;
}
