import(/* webpackMode: "eager" */ "/home/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/home/app/src/shared/apollo/apollo-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/app/src/shared/context/AuthContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/app/src/shared/registry/AntdRegistry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/app/src/shared/registry/registry.tsx");
